import React, { useEffect, useState } from "react";
// import webVideo from "./assets/star_desktop.mp4";
// import mobileVideo from "./assets/star_mobile.mp4";
import logo from "./assets/whitelong.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [videoSource, setVideoSource] = useState(null);

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      // setVideoSource(isMobile ? mobileVideo : webVideo);
      const webVideo = "/star_desktop.mp4"; 
      const mobileVideo = "/star_mobile.mp4"; 
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  const scrollToNextSection = () => {
    const starlinkSection = document.getElementById("starlink2");
    if (starlinkSection) {
      starlinkSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="landing">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>
      <div className="contentLanding">
        <img src={logo} alt="logowhite" className="landing-logo" />
        <p className="landing-text">{t("явдаг интернэт")}</p>
        <div className="landing-button">
          <button
            className="custom-button"
            onClick={() => scrollToNextSection()}
          >
            {t("ШИЙДЭЛ")}
          </button>
          <button
            className="custom-button"
            onClick={() => navigate(`/buy-list`)}
          >
            {t("ХУДАЛДАЖ АВАХ")}
          </button>
          <button
            className="custom-button"
            onClick={() => navigate(`/rent-list`)}
          >
            {t("ТҮРЭЭСЛЭХ")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
