import React, { useState } from "react";
import fb_black from "../../assets/icons/7_black.png";
import b_long from "../../assets/black_logo_long.png";
import insta_black from "../../assets/icons/8_black.png";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const items = [
    {
      question: "Төхөөрөмжийг анх аваад хэрхэн идэвхжүүлэх вэ?",
      answers: ["Идэвхжүүлэх зааврыг та энд дарж татаж авна уу."],
    },
    {
      question:
        "Starlink Nomad төхөөрөмж нь хаана ч сүлжээ барих уу? Монголын утасны операторуудын сүлжээтэй хамааралтай юу?",
      answers: [
        "Энэхүү төхөөрөмж нь Монгол орны газар нутаг болон Дэлхийн хаанаас ч сансрын хиймэл дагуулуудтай холбогдож хэзээ ч хаана ч интернэтэд холбогдох боломжтой юм. Монголын гар утасны операторуудын сүлжээтэй огт хамааралгүй буюу утасны сүлжээгүй болон сүлжээтэй ямар ч газар ажиллана. ",
      ],
    },
    {
      question: "Starlink Nomad -р гар утасны дугаар руу ярьж болох уу?",
      answers: [
        "Старлинк Номад төхөөрөмжөөр нэгэнт өндөр хурдны интернэтэд холбогдсон бол харилцаа холбооны бүх шийдлийг ашиглаж болно. Та гар утсан дээрээ IP дугаарын аппликэшн суулгаснаар ямар ч операторын энгийн дугаар руу шууд дуудлага хийж дуудлага хүлээж авч болно. Жишээ нь: LIME – Виртуал оператор апп.",
      ],
    },
    {
      question:
        "Төхөөрөмжөө худалдаж авсны дараа сар тутмын төлбөр төлдөг үү? ",
      answers: [
        "250,000₮ ийн төлбөрөөр нэг сарын турш хязгааргүй дата хэрэглээг авна. Төлбөр нь дараа төлбөрт зарчимтай.",
      ],
    },
    {
      question: "Төхөөрөмжөө түр ашиглахгүй бол төлбөрөө зогсоож болох уу?",
      answers: [
        "Болно. www.starlink.com дээр өөрийн бүртгэл нууц үгээр нэвтэрч PAUSE дарж түр зогсоож болно. Түр зогсолтын хугацаа хязгааргүй ба та хүссэн үедээ буцаан идэвхжүүлж болно. Зааврыг эндээс татаж авна уу.",
      ],
    },
    {
      question: "Төлбөрөө хэнд, хаана төлөх вэ?",
      answers: [
        "Aнх төхөөрөмжийг идэвхжүүлэхдээ www.starlink.com дээр эзэмшигчийн нэр дээр бүртгэл үүсгэх ба уг явцад олон улсын төлбөрийн карт холбож өгдөг. Уг картаас эхний сарын төлбөр татагдах ба цаашид уг картаас сар бүрийн төлбөр автоматаар татагдах болно. Төлбөрийг манай компани биш АНУ-ын Starlink компани авч байгаа болно.",
      ],
    },
    {
      question: "Сар бүрийн төлбөртөө НӨАТ авч болох уу?",
      answers: [
        "Болно. Старлинкын манай улс дахь төлөөлөгч Старлинк Сервис Монголиа ХХК руу 7777777 дугаарт залгаж НӨАТ – ын баримтаа авч болно. Та анх идэвхжүүлэх явцад VAT гэсэн форм дээр өөрийн эсвэл байгууллагын регистр дугаараа оруулсан байх ёстой.",
      ],
    },
    {
      question:
        "Старлинк Номад төхөөрөмжийг зөвхөн Монголдоо л хэрэглэх боломжтой юу? Өөр улс оронд хэрэглэж болох уу?",
      answers: [
        "Старлинк Номад төхөөрөмж нь одоогоор Дэлхийг тойрон ажиллаж буй 5874+ хиймэл дагуулаас сүлжээ авдаг тул цөөн хэдэн ашиглах боломжгүй улсуудаас бусад дэлхийн хаана ч ашиглах боломжтой.",
      ],
    },
    {
      question: "Хил орчмын бүсэд ажиллах уу?",
      answers: [
        "Манай улсын хөрш орнууд БНХАУ, ОХУ -ууд старлинкын сүлжээг нутаг дэвсгэр дээрээ зөвшөөрөөгүй байгаа тул уг хориотой холбоотойгоор манай улсын хил орчмын хэсгээр төхөөрөмж ажиллахгүй. Бидний туршилтаар хилийн зурвасаас 2 ~ 30км хэлбэлзэлтэй зайд тасарч байсан. www.starlink.com/maps эндээс та хамрах хүрээг харж болно.",
      ],
    },
    {
      question:
        "Старлинк Номад хөдөлгөөнт төхөөрөмжийг суурин ашиглаж болох уу?",
      answers: [
        "Болно. Манай төхөөрөмж нь цахилгаан тэжээлийн бүх боломжийг дэмждэг буюу 12в, 24в, 220в -р ажиллах тул та эдгээр төрлийн цахилгаан тэжээлтэй хаана ч суурин байдлаар ашиглаж болно.",
      ],
    },
    {
      question:
        " Старлинк Номад хөдөлгөөнт төхөөрөмжийн цахилгаан зарцуулалт ямар байдаг вэ?",
      answers: [
        "Манай төхөөрөмжүүд нь автомашины баттерей болон бусад 12в тогтмол хүчдэлийн эх үүсвэрээс ажилладаг ба Starlink Nomad Gen2 цагт дунджаар 60 ватт, Starlink Nomad Gen3 цагт дунджаар 90 ватт цахилгаан зарцуулна.",
      ],
    },
    {
      question:
        "Starlink Nomad – Явдаг интернэт нь Старлинкын Flat High Performance явуулын загвараас юугаараа яалгаатай вэ? Давуу, тал нь юу вэ?",
      answers: [
        "Starlink Nomad төхөөрөмж нь Монгол орны эрс тэс уур амьсгал цаг агаарын хүнд нөхцөлд зориулан бат бэх байдлаар шийдэж ашиглахад хялбар байхаар бүгд нэг хайрцагт багтааж хялбаршуулсан Монгол залуусын шийдлээр угсарсан төхөөрөмж юм",
        "Flat high Performance нь өндөр үнэтэй, зөвхөн 220В-р ажиллах ба хэмжээ илүү том, жин илүү хүнд мөн 3 төхөөрөмжийг угсарч холбож байж ажилладаг механик ажиллагаа ихтэй. Сарын төлбөр 950.000 мөртөө зөвхөн дата хязгаартай багцын үйлчилгээг дэмждэг. Машин дээр байрлуулахад тохиромж муутай шийдэлтэй, WiFi цацагч роутер нь 5-р үеийнх байдаг бол Starlinк Nomad-ыг 6-р үеийн WiFi цацагчаар сайжруулж угсарсан тул илүү их талбайд сүлжээгээ цацдаг.",
        "Starlink Nomad нь Starlink Standard төхөөрөмжийг ашиглан өөрчилж сайжруулж хийсэн тул Flat High Performance -с үнийн хувьд хямд болж чадсан. Ажиллах зарчим, интернэтийн хурд зэрэг нь ялгаагүй. Starlink Nomad нь стандарт загварын сарын хураамж буюу 250,000₮ -р хязгааргүй дата багцыг дэмжиж ажилладгаараа давуу юм.",
      ],
    },
    {
      question:
        "Starlink Standard Gen3 буюу суурин загвар болон Starlink Standard Gen2 суурин загвар хоёрын ялгаа юу вэ?",
      answers: [
        "Starlink Standard Gen3 нь старлинкээр хамгийн сүүлд гаргасан шинэ загвар ба Манай улс болон дэлхийн ихэнх улс оронд албан ёсоор борлуулж эхлээгүй байгаа юм. Техник үзүүлэлтийн хувьд өмнөх Gen2 загвараас 30%-р сүлжээний хурд, тогтвортой байдлаар илүү. Сүлжээнд холбогдох хурд өмнөх загвараас 5 дахин хурдан. Gen2 загвар нь WiFi  цацагч роутер нь 5-p үеийнх бол Gen3-ын 6-р үеийн цацагч илүү өргөн талбайд цацах чадвартай",
        "Gen2 утастай сүлжээний LAN портгүй тул заавал нэмэлт адаптер худалдаж авч холбох шаардлага гардаг байсан бол Gen3 загвар 2 ширхэг LAN порттой юм.",
      ],
    },
  ];
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const showItem = (index) => {
    setSelectedItemIndex(selectedItemIndex === index ? null : index);
  };
  const [isVisible, setIsVisible] = useState(false);
  const handleFbClick = () => {
    window.open("https://www.facebook.com/starlinknomad", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/starlinknomad/", "_blank");
  };

  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="faq2-section">
      <h3 className="faq2-h3">ТҮГЭЭМЭЛ АСУУЛТУУД</h3>
      <div className="faq2-container">
        {items.map((item, index) => (
          <div
            className={`paragraph ${
              selectedItemIndex === index ? "clicked" : ""
            }`}
            onClick={() => showItem(index)}
          >
            <div key={index} className="faq2-li">
              <h5
                className={`faq2-q ${
                  selectedItemIndex === index ? "clicked" : ""
                }`}
              >
                <span>{item.question}</span>
              </h5>
              <div style={{ height: 20, width: 20, marginLeft: 60 }}>
                <img
                  style={{ height: "100%", width: "100%" }}
                  src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
                ></img>
              </div>
            </div>
            {selectedItemIndex === index && (
              <div
                className={`faq2-desc ${
                  selectedItemIndex === index ? "clicked" : ""
                }`}
              >
                {item.answers.map((subItem, index) => (
                  <p key={index} className="faq2-p">
                    {subItem}
                  </p>
                ))}
              </div>
            )}

            <hr
              style={{
                color: "white",
                display: "flex",
                margin: "0px auto",
              }}
            />
          </div>
        ))}
        
      </div>
      <div className="faq2-footer">
        <div className="faq2-footer-container" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: 20,
            }}
          >
            <div style={{ height: 15 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={b_long}
                alt="logo"
              />
            </div>
            <div
              className="faq2-footer-text margin-left-28"
              onClick={() => navigate(`/home?id=${"starlink3"}`)}
            >
              Түгээмэл асуултууд
            </div>
            <div
              className="faq2-footer-text  margin-left-28"
              onClick={() => navigate("/contact")}
            >
              Холбоо барих
            </div>
            <div
              className="faq2-footer-text  margin-left-28"
              onClick={callInstruction}
            >
              Заавар татах
            </div>
            {isVisible && (
              <>
                <div
                  className="faq2-footer-text margin-left-28"
                  onClick={() =>
                    window.open("/pdf/MobileBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Хөдөлгөөнт идэвхжүүлэх
                </div>
                <div
                  className="faq2-footer-text margin-left-28"
                  onClick={() =>
                    window.open("/pdf/SuurinBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Суурин идэвхжүүлэх
                </div>
              </>
            )}
          </div>
          <div className="faq2-footer-contact">
            <img src={fb_black} alt="facebook" onClick={handleFbClick} />
            <img
              src={insta_black}
              alt="instagram"
              style={{ marginLeft: 5 }}
              onClick={handleInstaClick}
            />
          </div>
        </div>
        <div>
          <hr
            style={{
              color: "#a3abb1",
              margin: "0px auto",
              justifyContent: "center",
              width: 1400,
              height: "1px",
            }}
          />
        </div>
        <div className="faq2-footer-container" style={{ marginTop: -10 }}>
          <div className="faq2-footer-text">
            Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
            тоот(Циркийн урд)
          </div>
          <div className="faq2-footer-text faq-number">+976 75990033</div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
