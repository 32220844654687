import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import stants from "../../assets/others/yavdag_stants.png";
import gen2 from "../../assets/gallery-img/gen2_1.png";
import wifi from "../../assets/others/outdoor_wifi6.png";
import other from "../../assets/others/starlink_1224cable_cigar.png";
import standard_gen3 from "../../assets/others/standard_gen3.webp";
import arrow from "../../assets/icons/ic_arrow_left_gray.png";
import Header from "../../Header";
import ToolbarSecond from "../../ToolbarSecond";
import FooterL from "../../Footer";
import { products } from "../Dummy";

const BuyMain = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(1);
  console.log("selectedItem: ", selectedItem);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addItems, setAddItem] = useState([]);
  useEffect(() => {
    const productId = Number(selectedItem);
    const oneProduct = products.find((p) => p.id === productId);

    if (oneProduct) {
      const filteredProducts = products.filter(
        (item) =>
          oneProduct.additional && oneProduct.additional.includes(item.id)
      );
      if (filteredProducts) {
        setAddItem(filteredProducts);
      }
    } else {
      navigate("/some-error-page");
    }
  }, [selectedItem, navigate]);

  const menuItem = [
    {
      id: 1,
      name: "Явдаг интернэт",
      img: gen2,
    },
    {
      id: 2,
      name: "Явдаг станц",
      img: stants,
    },
    {
      id: 3,
      name: "Суурин Старлинк",
      img: standard_gen3,
    },
    {
      id: 4,
      name: "Сүлжээ тэлэгч",
      img: wifi,
    },
    {
      id: 5,
      name: "Нэмэлт хэрэгслүүд",
      img: other,
    },
  ];
  const callAllProduct = (id) => {
    console.log("cl");
    navigate(`/all/${id}`);
  };
  const changeItems = (id) => {
    setSelectedItem(id);
    const filteredItems = products.filter((item) => item.id === id);
    if (filteredItems) {
      setSelectedItems(filteredItems);
    }
  };
  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedItem(selectedId);
    changeItems(selectedId);
  };
  return (
    <div>
      <Header></Header>
      <ToolbarSecond></ToolbarSecond>

      <div
      className="buy-out"
      >
        <div className="buy-main-container">
          <div className="buy-main-d-f ">
            <div className="bm-filter">
              <div className="buy-main-sub">
                <h3 className="font-size-20">Сонголт</h3>
              </div>
              {menuItem.map((item, index) => (
                <div
                  className={`buy-menu ${
                    selectedItem === item.id ? "clicked" : ""
                  }`}
                  onClick={() => changeItems(item.id)}
                >
                  <div className="ic-45">
                    <img
                      src={item.img}
                      className="starlink-icon"
                      alt="Starlink Gen3"
                    />
                  </div>
                  <span className="margin-left-20">{item.name}</span>
                </div>
              ))}
            </div>
            <div className="buy-menu-dropdown-container">
              <select
                className="buy-menu-dropdown"
                value={selectedItem}
                onChange={handleChange}
              >
                {menuItem.map((item, index) => (
                  <option key={index} value={item.id}>
                    <div className="dropdown-option-content">
                      <div className="ic-45">
                        <img
                          src={item.img}
                          className="starlink-icon"
                          alt="Starlink Gen3"
                        />
                      </div>
                      <span className="margin-left-20">{item.name}</span>
                    </div>
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="buy-main-header">
                {selectedItem !== 5 && (
                  <h3 className="font-size-18">
                    {products[selectedItem - 1].name}
                  </h3>
                )}
                <div
                  className="show-all display-row"
                  onClick={() => callAllProduct(1)}
                >
                  <span className="font-size-14 margin-left-20">
                    Бүгдийг харах
                  </span>
                  <div className="b-link-text">
                    <img
                      src={arrow}
                      className="filter starlink-icon"
                      alt="ral"
                    ></img>
                  </div>
                </div>
              </div>
              {selectedItem !== 5 && (
                <div className="bm-container-filter">
                  <div
                    className="buy-item"
                    onClick={() =>
                      navigate(`/buy/${products[selectedItem - 1].id}`)
                    }
                  >
                    <div className="buy-item-img">
                      <img
                        src={products[selectedItem - 1].img}
                        className="starlink-icon"
                        alt="Starlink Gen3 Large"
                      />
                    </div>
                    <span className="buy-main-name">
                      {products[selectedItem - 1].name}
                    </span>
                    <span className="amount">
                      MNT {products[selectedItem - 1].price.toLocaleString()}
                    </span>
                  </div>
                  <div className="buy-grid">
                    {addItems &&
                      addItems.map((subItem, index) => (
                        <div key={index} className="buy-grid-item">
                          {subItem.id !== 99 && (
                            <>
                              <div
                                className="buy-img-cont"
                                onClick={() => navigate(`/buy/${subItem.id}`)}
                              >
                                <img
                                  src={subItem.img}
                                  className="starlink-icon"
                                  alt="Starlink Gen3 Small"
                                />
                              </div>

                              <span className="buy-item-name ">
                                {subItem.name}
                              </span>
                              <span className="amount">
                                MNT {subItem.price.toLocaleString()}
                              </span>
                            </>
                          )}
                          {subItem.id === 99 && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 70,
                                }}
                                key={index}
                                onClick={() => callAllProduct(2)}
                              >
                                <span className="buy-item-name ">
                                  {subItem.name}
                                </span>
                                <div
                                  style={{
                                    height: 40,
                                    width: 40,
                                    marginTop: 20,
                                  }}
                                >
                                  <img
                                    src={subItem.img}
                                    className="starlink-icon"
                                    alt="Starlink Gen3 Small"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {selectedItem === 1 && (
                <>
                  <div className="buy-second-item">
                    <div className="buy-main-header" style={{ marginTop: 40 }}>
                      <h3 className="font-size-18">
                        Starlink Nomad - Явдаг интернэт (Gen 2)
                      </h3>
                    </div>
                    <div className="bm-container-filter">
                      <div
                        className="buy-item"
                        onClick={() => navigate(`/buy/${5}`)}
                      >
                        <div className="buy-item-img">
                          <img
                            src={products[4].img}
                            className="starlink-icon"
                            alt="Starlink Gen3 Large"
                          />
                        </div>
                        <span className="buy-main-name">
                          {products[4].name}
                        </span>
                        <span className="amount">
                          MNT {products[4].price.toLocaleString()}
                        </span>
                      </div>
                      <div className="buy-grid">
                        {addItems &&
                          addItems.map((subItem, index) => (
                            <div key={index} className="buy-grid-item">
                              {subItem.id !== 99 && (
                                <>
                                  <div
                                    className="buy-img-cont"
                                    onClick={() =>
                                      navigate(`/buy/${subItem.id}`)
                                    }
                                  >
                                    <img
                                      src={subItem.img}
                                      className="starlink-icon"
                                      alt="Starlink Gen3 Small"
                                    />
                                  </div>

                                  <span className="buy-item-name ">
                                    {subItem.name}
                                  </span>
                                  <span className="amount">
                                    MNT {subItem.price.toLocaleString()}
                                  </span>
                                </>
                              )}
                              {subItem.id === 99 && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 70,
                                    }}
                                    key={index}
                                    onClick={() => callAllProduct(3)}
                                  >
                                    <span className="buy-item-name ">
                                      {subItem.name}
                                    </span>
                                    <div
                                      style={{
                                        height: 40,
                                        width: 40,
                                        marginTop: 20,
                                      }}
                                    >
                                      <img
                                        src={subItem.img}
                                        className="starlink-icon"
                                        alt="Starlink Gen3 Small"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ height: "10%" }}></div>
                  </div>
                </>
              )}
              {selectedItem === 2 && (
                <>
                  <div className="buy-second-item">
                    <div className="buy-main-header" style={{ marginTop: 40 }}>
                      <h3 className="font-size-18">{products[5].name}</h3>
                    </div>
                    <div className="bm-container-filter">
                      <div
                        className="buy-item"
                        onClick={() => navigate(`/buy/${6}`)}
                      >
                        <div className="buy-item-img">
                          <img
                            src={products[5].img}
                            className="starlink-icon"
                            alt="Starlink Gen3 Large"
                          />
                        </div>
                        <span className="buy-main-name">
                          {products[5].name}
                        </span>
                        <span className="amount">
                          MNT {products[5].price.toLocaleString()}
                        </span>
                      </div>
                      <div className="buy-grid">
                        {addItems &&
                          addItems.map((subItem, index) => (
                            <div key={index} className="buy-grid-item">
                              {subItem.id !== 99 && (
                                <>
                                  <div
                                    className="buy-img-cont"
                                    onClick={() =>
                                      navigate(`/buy/${subItem.id}`)
                                    }
                                  >
                                    <img
                                      src={subItem.img}
                                      className="starlink-icon"
                                      alt="Starlink Gen3 Small"
                                    />
                                  </div>

                                  <span className="buy-item-name ">
                                    {subItem.name}
                                  </span>
                                  <span className="amount">
                                    MNT {subItem.price.toLocaleString()}
                                  </span>
                                </>
                              )}
                              {subItem.id === 99 && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 70,
                                    }}
                                    key={index}
                                    onClick={() => callAllProduct(4)}
                                  >
                                    <span className="buy-item-name ">
                                      {subItem.name}
                                    </span>
                                    <div
                                      style={{
                                        height: 40,
                                        width: 40,
                                        marginTop: 20,
                                      }}
                                    >
                                      <img
                                        src={subItem.img}
                                        className="starlink-icon"
                                        alt="Starlink Gen3 Small"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ height: "10%" }}></div>
                  </div>
                </>
              )}
              {selectedItem === 5 && (
                <>
                  <div className="buy-second-item">
                    <div className="buy-main-header" style={{ marginTop: 40 }}>
                      <div className="buy-grid-additional">
                        {addItems &&
                          addItems.map((item, index) => (
                            <div key={index} className="buy-grid-item">
                              <div
                                className="buy-img-cont"
                                onClick={() => navigate(`/buy/${item.id}`)}
                              >
                                <img
                                  src={item.img}
                                  className="starlink-icon"
                                  alt="Starlink Gen3 Small"
                                />
                              </div>
                              <span className="buy-item-name ">
                                {item.name}
                              </span>
                              <span className="amount">
                                MNT {item.price.toLocaleString()}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ height: "10%" }}></div>
                  </div>
                </>
              )}
              <div style={{ height: 80 }}></div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f9fa",
        }}
        className="buy-button-container "
      >
        <FooterL></FooterL>
      </div>
    </div>
  );
};

export default BuyMain;
